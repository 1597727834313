const $ = require("jquery");

function runAutoScroll(element, speed = 800, offset = 100) {
  if (element) {
    $([document.documentElement, document.body]).animate({
      scrollTop: $(element).offset().top - offset,
    }, speed, 'swing');
  }
}

$('#to-top .to-top-content').on('click', function () {
  runAutoScroll($('#top'));
})
